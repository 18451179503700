import { render, staticRenderFns } from "./InfoDetail.vue?vue&type=template&id=221af745&scoped=true&"
import script from "./InfoDetail.vue?vue&type=script&lang=js&"
export * from "./InfoDetail.vue?vue&type=script&lang=js&"
import style0 from "./InfoDetail.vue?vue&type=style&index=0&id=221af745&scoped=true&lang=css&"
import style1 from "./InfoDetail.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221af745",
  null
  
)

export default component.exports
import {QPageSticky,QTabs,QTab,QTabPanels,QTabPanel,QCard,QMarkupTable,QTable,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QBtn,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPageSticky,QTabs,QTab,QTabPanels,QTabPanel,QCard,QMarkupTable,QTable,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
